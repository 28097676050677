/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ShapedImage from '../components/shaped-image';
import OGImage from '../images/free-stuff-for-freelancers-og-image.png';

const FreeStuffForFreelancersPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
        >
            <SEO
                title="Free Stuff For Freelancers"
                description="Completely free books, content, courses and resources for freelancers."
                imageAlt="Free Stuff For Freelancers"
                imageUrl={ `https://www.tomhirst.com${OGImage}` }
            />
            <Section narrow>
                <h1>Free Stuff For Freelancers</h1>
                <p>On this page you&apos;ll find a list of free books, content, courses and resources for freelancers.</p>
                <a className="button is-green has-icon" href="#resources">
                    Take me straight to the free stuff
                    <FontAwesomeIcon icon="arrow-down" />
                </a>
                <p>If you know of a useful resource, or have authored something for freelancers yourself, please <a href="mailto:hello@tomhirst.com?Subject=I want to list a free resource for freelancers!">email me</a> with the details and your item will be considered for listing.</p>
                <p>I&apos;ll update this page with new items as and when they have been checked for quality.</p>
                <p>The 2 rules for a resource to be listed on this page are:</p>
                <ul>
                    <li>It must be original</li>
                    <li>It must be available (legally) completely free of charge</li>
                </ul>
                <p>Enjoy the free stuff for freelancers below!</p>
                <h2 id="resources">Free Books/Ebooks For Freelancers</h2>
                <p>In this section, you&apos;ll find a list of free books/ebooks for freelancers:</p>
                <a className="resource" href="https://tomhir.st/10-steps">
                    <div className="resource-image">
                        <ShapedImage
                            square
                            name="10-steps-cover-square.jpg"
                            alt="10 Steps To Becoming A Better Freelancer by Tom Hirst"
                        />
                    </div>
                    <div className="resource-content">
                        <h3>10 Steps To Becoming A Better Freelancer</h3>
                        <p>How to get paid what you&apos;re worth, work smarter and find balance.</p>
                        <p className="resource-author">By Tom Hirst</p>
                    </div>
                </a>
                <a className="resource" href="https://gumroad.com/l/freelancing101">
                    <div className="resource-image">
                        <ShapedImage
                            square
                            name="freelancing-101-cover.jpg"
                            alt="Freelancing 101 by Matt Olpinski"
                        />
                    </div>
                    <div className="resource-content">
                        <h3>Freelancing 101</h3>
                        <p>Answers to common freelancing questions.</p>
                        <p className="resource-author">By Matt Olpinski</p>
                    </div>
                </a>
                <h2>Free Services For Freelancers</h2>
                <p>This section contains a list of free services that are useful for freelancers:</p>
                <a className="resource" href="https://forwardr.io">
                    <div className="resource-image">
                        <ShapedImage
                            square
                            name="forwardr-square.jpg"
                            alt="Forwardr, freelancer to freelancer opportunity sharing by Tom Hirst"
                        />
                    </div>
                    <div className="resource-content">
                        <h3>Forwardr</h3>
                        <p>Find new freelancing opportunities and list your excess leads for free.</p>
                        <p className="resource-author">By Tom Hirst</p>
                    </div>
                </a>
                <hr />
                <a className="button is-green has-icon" href="mailto:hello@tomhirst.com?Subject=I want to list a free resource for freelancers!">
                    I want to list a free resource for freelancers!
                    <FontAwesomeIcon icon="envelope" />
                </a>
            </Section>
        </Layout>
    );
};

FreeStuffForFreelancersPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

FreeStuffForFreelancersPage.defaultProps = {
    location: {},
};

export default FreeStuffForFreelancersPage;
